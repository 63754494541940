import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import FilterOverlay from "./FilterOverlay";
import userIcon from "./images/userIcon.png";
import "./SearchResultsPage.css";
import { useNavigate } from "react-router-dom";
import event_image from "./images/event_placeholder_image.jpg";
import SearchBar from "./SearchBar";
import { useLocation } from "react-router-dom";

function SearchResultsPage() {
  const [showFilter, setShowFilter] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const updateSearchResults = (results) => {
    setSearchResults(results);
  };
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };
  const navigateToReview = async (id) => {
    navigate("/review/" + id, {
      state: { id },
    });
  };
  const navigateToEvent = async (id) => {
    navigate("/event/" + id, {
      state: { id },
    });
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchTerm = params.get("query");

    if (searchTerm) {
      const fetchSearchResults = async () => {
        try {
          const response = await axios.get(
            process.env.REACT_APP_BASE_URL + "/search/" + searchTerm
          );
          setSearchResults(response.data);
        } catch (error) {
          console.error("Error fetching search results:", error);
        }
      };

      fetchSearchResults();
    }
  }, [location]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const startDate = params.get("startDate");
    const endDate = params.get("endDate");
    const category = params.get("category");

    // If there are filter parameters, perform the search
    if (startDate || endDate || category) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/search/filter/`, {
          params: {
            start_date: startDate,
            end_date: endDate,
            category: category,
          },
        })
        .then((response) => {
          setSearchResults(response.data);
        })
        .catch((error) => {
          console.error("Error fetching search results:", error);
        });
    }
  }, [location]);

  return (
    <div className="search-results-dashboard">
      <Sidebar />
      <div className="search-results-main-content">
        <header>
          <SearchBar onSearch={updateSearchResults} />

          <button className="filter-btn" onClick={toggleFilter}>
            Filter Events
          </button>
          <FilterOverlay
            show={showFilter}
            closeFilter={toggleFilter}
            updateSearchResults={updateSearchResults}
          />
          <button className="user-btn">
            <img src={userIcon} alt="User Icon" className="user-btn-img" />
            User Profile
          </button>
        </header>

        <div className="search-results-container">
          <h2>Search Results</h2>
          <div className="results">
            {searchResults &&
              searchResults.map((result) => (
                <div className="event-card" key={result.id}>
                  <img
                    className="event-image"
                    src={event_image}
                    alt="Fly High Logo"
                  />
                  <div className="event-overlay">
                    <div className="event-details">
                      <button
                        onClick={() => navigateToEvent(result.id)}
                        className="event-text"
                      >
                        {result.title}
                      </button>
                    </div>
                    <button
                      onClick={() => navigateToReview(result.id)}
                      className="review-btn"
                    >
                      Leave Review
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchResultsPage;
