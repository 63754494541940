import React, { useState } from "react";
import axios from "axios";

function SearchBar({ onSearch, onSubmit }) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchInput = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (onSubmit) {
        onSubmit(searchTerm);
      } else {
        try {
          const response = await axios.get(
            process.env.REACT_APP_BASE_URL + "/search/" + searchTerm
          );
          onSearch(response.data);
        } catch (error) {
          console.error("Error fetching search results:", error);
        }
      }
    }
  };

  return (
    <input
      className="search-bar"
      type="text"
      placeholder="Search for an Event Title"
      value={searchTerm}
      onChange={handleSearchInput}
      onKeyDown={handleKeyDown}
    />
  );
}

export default SearchBar;
