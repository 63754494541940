import React from "react";
import "./FilterOverlay.css";
import { useNavigate } from "react-router-dom";

const FilterOverlay = ({ show, closeFilter }) => {
  const navigate = useNavigate();
  if (!show) {
    return null;
  }

  function handleApply(event) {
    event.preventDefault();
    const startDate = document.getElementById("startDate").value;
    const endDate = document.getElementById("endDate").value;
    const category = document.getElementById("category").value;
    const queryParams = new URLSearchParams();
    if (startDate) queryParams.append("startDate", startDate);
    if (endDate) queryParams.append("endDate", endDate);
    if (category) queryParams.append("category", category);

    navigate("/searchresults?" + queryParams.toString());
    closeFilter();
  }

  return (
    <div className="filter-overlay">
      <div className="filter-content">
        <button onClick={closeFilter} className="close-button">
          X
        </button>
        <h2>Filter Events</h2>
        <form>
          <label htmlFor="date">Date Range From: </label>
          <input type="date" id="startDate" name="date" />
          <span> To: </span>
          <input type="date" id="endDate" name="date" />
          <br></br>
          <label htmlFor="category">Category: </label>
          <select id="category" name="category">
            <option>Academic</option>
            <option>Career</option>
            <option>Sports</option>
            <option>Entertainment</option>
            <option>Other</option>
          </select>
          <br></br>
          <br></br>
          <button className="submit" onClick={handleApply}>
            Apply Filters
          </button>
        </form>
      </div>
    </div>
  );
};

export default FilterOverlay;
