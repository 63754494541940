import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import Sidebar from "./Sidebar";
import "./ProfilePage.css";

export default function ProfilePage() {
  const navigate = useNavigate();

  const [loadData, setLoadData] = useState(true);

  const [editingEvent, setEditingEvent] = useState(false);
  const [editingId, setEditingId] = useState(-1);

  const [reviewRating, setReviewRating] = useState(null);
  const [reviewComment, setReviewComment] = useState(null);

  const [eventTitle, setEventTitle] = useState(null);
  const [eventDescription, setEventDescription] = useState(null);
  const [eventDate, setEventDate] = useState(null);
  const [eventLocation, setEventLocation] = useState(null);
  const [eventLimit, setEventLimit] = useState(null);
  const [eventRequireRSVP, setEventRequireRSVP] = useState(null);
  const [eventCategory, setEventCategory] = useState(null);

  const [events, setEvents] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [rsvps, setRsvps] = useState([]);
  const { id, type } = useParams();

  useEffect(() => {
    if (id !== localStorage.getItem("id")) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getUserData = async () => {
      const events = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/profile/getUserEvents?user_id=${id}`
      );
      console.log(JSON.stringify(events.data));
      setEvents(events.data);

      const reviews = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/profile/getUserReviews?user_id=${id}`
      );
      console.log(JSON.stringify(reviews.data));
      setRatings(reviews.data);

      const rsvps = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/profile/getUserRSVPs?user_id=${id}`
      );
      console.log(JSON.stringify(rsvps.data));
      setRsvps(rsvps.data);
    };

    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  const deleteEvent = async (e) => {
    await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/profile/deleteEvent?event_id=${e.target.id}`
    );
    setLoadData(!loadData);
  };

  const deleteReview = async (e) => {
    await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/profile/deleteReview?review_id=${e.target.id}`
    );
    setLoadData(!loadData);
  };

  const deleteRSVP = async (e) => {
    await axios.delete(`${process.env.REACT_APP_BASE_URL}/profile/deleteRSVP?rsvp_id=${e.target.id}`);
    setLoadData(!loadData);
  };

  const updateReview = async () => {
    var body = {};

    if (reviewRating) {
      const rating = { rating: reviewRating };
      body = { ...body, ...rating };
    }
    if (reviewComment) {
      const comment = { comment: reviewComment };
      body = { ...body, ...comment };
    }

    console.log("body: " + JSON.stringify(body));
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/profile/updateReview?review_id=${editingId}`,
      body
    );

    setReviewRating(null);
    setReviewComment(null);
    setEditingId(-1);
    setLoadData(!loadData);
  };

  const updateEvent = async (e) => {
    var body = {};

    if (eventTitle) {
      const title = { title: eventTitle };
      body = { ...body, ...title };
    }
    if (eventDescription) {
      const description = { description: eventDescription };
      body = { ...body, ...description };
    }
    if (eventDate) {
      const date = { date: eventDate };
      body = { ...body, ...date };
    }
    if (eventLocation) {
      const location = { location: eventLocation };
      body = { ...body, ...location };
    }
    if (eventLimit) {
      const limit = { participant_limit: eventLimit };
      body = { ...body, ...limit };
    }
    if (eventRequireRSVP !== null) {
      const rsvpRequired = { rsvp_required: eventRequireRSVP };
      body = { ...body, ...rsvpRequired };
    }
    if (eventCategory !== null) {
      const category = { category: eventCategory };
      body = { ...body, ...category };
    }

    console.log("body: " + JSON.stringify(body));
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/profile/updateEvent?event_id=${editingId}`,
      body
    );

    setEventTitle(null);
    setEventDescription(null);
    setEventDate(null);
    setEventLimit(null);
    setEventLocation(null);
    setEventRequireRSVP(null);
    setEventCategory(null);
    setEditingId(-1);
    setLoadData(!loadData);
  };

  const startEdit = (isEvent, id) => {
    setEditingEvent(isEvent);
    setEditingId(id);
  };
  const endEdit = () => {
    setEditingId(-1);

    setReviewRating(null);
    setReviewComment(null);
    setEventTitle(null);
    setEventDescription(null);
    setEventDate(null);
    setEventLocation(null);
    setEventLimit(null);
    setEventRequireRSVP(null);
    setEventCategory(null);
  };

  return (
    <div className="profile-page">
      <Sidebar />
      <div className="profile-page-container">
        {type !== "ratings" && type !== "rsvps" && (
          <div className="profile-page-event-container">
            <h1>Events</h1>
            {events.length > 0 ? (
              events.map((event) => {
                return (
                  <div>
                    {!editingEvent || editingId !== event[0] ? (
                      <div className="profile-page-event-item">
                        <div>
                          <h2>{event[1]}</h2>
                          <h3>{event[3]}</h3>
                          <p>When: {new Date(event[7]).toDateString()}</p>
                          <p>Where: {event[4]}</p>
                          <p>Limit: {event[5] ?? "None"}</p>
                          <p>RSVP: {event[6] ? "Yes" : "No"}</p>
                          <p>Category: {event[8]}</p>
                        </div>
                        <div>
                          <button
                            className="profile-page-button"
                            onClick={() => startEdit(true, Number(event[0]))}
                          >
                            Update
                          </button>
                          <button
                            id={event[0]}
                            className="profile-page-button-delete"
                            onClick={deleteEvent}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="profile-page-event-item">
                        <div>
                          <h2>Title: </h2>
                          <input
                            type="text"
                            placeholder={event[1]}
                            onChange={(e) => {
                              setEventTitle(e.target.value);
                            }}
                          />
                          <h3>Description: </h3>
                          <input
                            type="text"
                            placeholder={event[3]}
                            onChange={(e) => {
                              setEventDescription(e.target.value);
                            }}
                          />

                          <p>When: </p>
                          <input
                            type="date"
                            placeholder={event[8]}
                            onChange={(e) => {
                              setEventDate(new Date(e.target.value));
                            }}
                          />
                          <p>Where: </p>
                          <input
                            type="text"
                            placeholder={event[4]}
                            onChange={(e) => {
                              setEventLocation(e.target.value);
                            }}
                          />
                          <p>Limit: {event[5] ?? "None"}</p>
                          <input
                            type="number"
                            min={0}
                            placeholder={event[5]}
                            onChange={(e) => {
                              setEventLimit(Number(e.target.value));
                            }}
                          />
                          <p>RSVP: </p>
                          <input
                            type="checkbox"
                            id={`${event[0]}RSVP`}
                            // checked={!event[6]}
                            // checked={false}
                            onChange={(e) => {
                              setEventRequireRSVP(e.target.checked);
                            }}
                          />
                          <label for={`${event[0]}RSVP`}>
                            RSVP required for event
                          </label>
                          <p>Category: </p>
                          <select
                            id={`${event[0]}CATEGORY`}
                            onChange={(e) => {
                              setEventCategory(e.target.value);
                            }}
                          >
                            <option disabled selected>
                              Choose Category
                            </option>
                            <option value="Academic">Academic</option>
                            <option value="Career">Career</option>
                            <option value="Sports">Sports</option>
                            <option value="Entertainment">Entertainment</option>
                            <option value="Other">Other</option>
                          </select>
                          <br></br>
                        </div>
                        <div>
                          <button
                            className="profile-page-button-confirm"
                            onClick={updateEvent}
                          >
                            Confirm
                          </button>
                          <button
                            className="profile-page-button"
                            id={event[0]}
                            onClick={endEdit}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <h3>No Events yet!</h3>
            )}
          </div>
        )}
        {type !== "events" && type !== "rsvps" && (
          <div>
            <h1>Ratings</h1>
            {ratings.length > 0 ? (
              ratings.map((rating) => {
                return (
                  <div>
                    {editingId !== rating[0] || editingEvent ? (
                      <div className="profile-page-review-item">
                        <div>
                          <h2>{rating[6]}</h2>
                          <p>Rating: {rating[3]}</p>
                          <p>Comment: {rating[4]}</p>
                          <p>Date: {new Date(rating[5]).toDateString()}</p>
                        </div>
                        <div>
                          <button
                            className="profile-page-button"
                            onClick={() => startEdit(false, Number(rating[0]))}
                          >
                            Update
                          </button>
                          <button
                            id={rating[0]}
                            className="profile-page-button-delete"
                            onClick={deleteReview}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="profile-page-review-item">
                        <div>
                          <h2>{rating[6]}</h2>
                          <p>Rating: </p>
                          <input
                            type="text"
                            placeholder={rating[3]}
                            onChange={(e) =>
                              setReviewRating(Number(e.target.value))
                            }
                          />
                          <p>Comment: </p>

                          <input
                            type="text"
                            placeholder={rating[4]}
                            onChange={(e) => {
                              setReviewComment(e.target.value);
                            }}
                          />

                          <p>Date: {new Date(rating[5]).toDateString()}</p>
                        </div>
                        <div>
                          <button
                            className="profile-page-button-confirm"
                            onClick={updateReview}
                          >
                            Confirm
                          </button>
                          <button
                            id={rating[0]}
                            className="profile-page-button"
                            onClick={endEdit}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <h3>No Ratings yet!</h3>
            )}
          </div>
        )}
        {type !== "events" && type !== "ratings" && (
          <div>
            <h1>RSVPs</h1>
            <div className="profile-page-rsvp-container">
              {rsvps.length > 0 ? (
                rsvps.map((rsvp) => {
                  return (
                    <div className="profile-page-rsvp-item">
                      <div>
                        <h2>{rsvp[4]}</h2>
                        <p>Date: {new Date(rsvp[5]).toDateString()}</p>
                        <p>Location: {rsvp[6]}</p>
                      </div>
                      <div>
                        <button
                          id={rsvp[0]}
                          className="profile-page-button-delete"
                          onClick={deleteRSVP}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <h3>No RSVPs yet!</h3>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
