import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { UserProvider } from "./UserContext.js";
import LoginPage from "./LoginPage";
import ProfilePage from "./ProfilePage";
import DashboardPage from "./DashboardPage";
import ReviewPage from "./ReviewPage";
import EventsPage from "./EventsPage";
import SignupPage from "./SignupPage";
import UserStatsPage from "./UserStatsPage";
import SearchResultsPage from "./SearchResultsPage.js";
import AddEventPage from "./AddEventPage"; 

function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/review/:event_id" element={<ReviewPage />} />
          <Route path="/event/:event_id" element={<EventsPage />} />
          <Route path="/profile/:id/:type?" element={<ProfilePage />} />
          <Route path="/" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/userstats" element={<UserStatsPage />} />
          <Route path="/searchresults" element={<SearchResultsPage />} />
          <Route path="/addevent" element={<AddEventPage />} />
          {/* <Route path="/myEvents" element={<MyEventsPage />} />
          <Route path="/rsvped" element={<RSVPPage />} />
          <Route path="/userStats" element={<UserStatsPage />} />
          <Route path="/post" element={<PostEventPage />} />
          <Route path="/settings" element={<SettingsPage />} /> */}
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;
