import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "./UserContext";
import "./LoginPage.css";
import axios from "axios";

function LoginPage() {
  const { setUser } = useUser();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [invalidLogin, setInvalidLogin] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async() => {
    const userData = {
      username: username,
      password: password,
    };
    console.log(userData);

    // Call login endpoint
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/user/login`, userData
    );
    console.log(response.data);

    if (!response.data.message) {
      setUser({
        id: response.data.id,
        username: response.data.username,
      });
      localStorage.setItem("id", response.data.id);
      localStorage.setItem("username", response.data.username);
      navigate("/dashboard");
    } else {
      setInvalidLogin(true);
      console.log("Invalid login");
    }
  };

  const handleSwitchToSignup = () => {
    navigate("/signup");
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h1>Login</h1>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="input-field"
        />
        <br></br>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input-field"
        />
        <br></br>
        <button onClick={handleLogin} className="button">
          Login
        </button>
        <br></br>
        <button onClick={handleSwitchToSignup} className="have-account-button">
          No Account? Sign Up!
        </button>
        {invalidLogin === true ? (
          <div>
            <br></br>
            <button className="invalid-login">Invalid login.</button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default LoginPage;
