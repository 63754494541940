import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "./Sidebar";
import FilterOverlay from "./FilterOverlay";
import "./DashboardPage.css";
import userIcon from "./images/userIcon.png";
import SearchBar from "./SearchBar";

import event_image from "./images/event_placeholder_image.jpg";

function DashboardPage() {
  const [upcomingEventsData, setUpcomingEventsData] = useState([]);
  const [popularEventsData, setPopularEventsData] = useState([]);
  const [recentEventsData, setRecentEventsData] = useState([]);
  const [allEventsData, setAllEventsData] = useState([]);
  const [pastEventsData, setPastEventsData] = useState([]);
  const [ratingEventsData, setRatingEventsData] = useState([]);
  
  const [showFilter, setShowFilter] = useState(false);

  const navigate = useNavigate();

  // This function will be called when the "Leave Review" button is clicked
  const navigateToReview = async (id) => {
    navigate("/review/" + id, {
      state: { id },
    });
  };

  // This function will be called when the "Event Name" button is clicked
  const navigateToEvent = async (id) => {
    navigate("/event/" + id, {
      state: { id },
    });
  };

  // This function will be called when the "User Profile" button is clicked
  const navigateToProfile = async () => {
    if (!localStorage.getItem("id")) {
      return
    }

    navigate("/profile/" + localStorage.getItem("id"));
  };
  
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleSearchSubmit = (searchTerm) => {
    navigate(`/searchresults?query=${encodeURIComponent(searchTerm)}`);
  };

  useEffect(() => {
    const getPopularEventsData = async() => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/events/get/events_by_rsvp`
      );
      console.log(res.data)
      setPopularEventsData(res.data);
    }
    const getRatingEventsData = async() => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/events/get/events_by_rating`
      );
      console.log(res.data)
      setRatingEventsData(res.data);
    }
    const getUpcomingEventsData = async() => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/events/get/upcoming`
      );
      console.log(res.data)
      setUpcomingEventsData(res.data);
    }
    const getRecentEventsData = async() => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/events/get/recent_events/5`
      );
      console.log(res.data)
      setRecentEventsData(res.data);
    }
    const getAllEventsData = async() => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/events/get/all`
      );
      console.log(res.data)
      setAllEventsData(res.data);
    }
    const getPastEventsData = async() => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/events/get/past`
      );
      console.log(res.data)
      setPastEventsData(res.data);
    }

    getPopularEventsData();
    getRatingEventsData();
    getUpcomingEventsData();
    getRecentEventsData();
    getAllEventsData();
    getPastEventsData();
  }, []);

  return (
    <div className="dashboard">
      <Sidebar />
      <div className="main-content">
        <header>
          <SearchBar onSubmit={handleSearchSubmit} />
          <button className="filter-btn" onClick={toggleFilter}>
            Filter Events
          </button>
          <FilterOverlay show={showFilter} closeFilter={toggleFilter} />
          <button className="user-btn" onClick={navigateToProfile}>
            <img src={userIcon} alt="User Icon" className="user-btn-img" />
            User Profile
          </button>
        </header>

        <section className="category-content">
          <h2>Most Popular</h2>
          <section className="event-container">
            {popularEventsData &&
              popularEventsData.map((d) => (
                <div className="event-card" key={d.id}>
                  <img className="event-image" src={event_image} alt="Event" />
                  <div className="event-overlay">
                    <div className="event-details">
                      <button
                        onClick={() => navigateToEvent(d.id)}
                        className="event-text"
                      >
                        {d.title}
                      </button>
                    </div>
                    <button
                      onClick={() => navigateToReview(d.id)}
                      className="review-btn"
                    >
                      Leave Review
                    </button>
                  </div>
                </div>
              ))}
          </section>
        </section>

        <section className="category-content">
          <h2>Highest Rating</h2>
          <section className="event-container">
            {ratingEventsData &&
              ratingEventsData.map((d) => (
                <div className="event-card" key={d.id}>
                  <img className="event-image" src={event_image} alt="Event" />
                  <div className="event-overlay">
                    <div className="event-details">
                      <button
                        onClick={() => navigateToEvent(d.id)}
                        className="event-text"
                      >
                        {d.title}
                      </button>
                    </div>
                    <button
                      onClick={() => navigateToReview(d.id)}
                      className="review-btn"
                    >
                      Leave Review
                    </button>
                  </div>
                </div>
              ))}
          </section>
        </section>

        <section className="category-content">
          <h2>Upcoming Events</h2>
          <section className="event-container">
            {upcomingEventsData &&
              upcomingEventsData.map((d) => (
                <div className="event-card" key={d.id}>
                  <img className="event-image" src={event_image} alt="Event" />
                  <div className="event-overlay">
                    <div className="event-details">
                      <button
                        onClick={() => navigateToEvent(d.id)}
                        className="event-text"
                      >
                        {d.title}
                      </button>
                    </div>
                    <button
                      onClick={() => navigateToReview(d.id)}
                      className="review-btn"
                    >
                      Leave Review
                    </button>
                  </div>
                </div>
              ))}
          </section>
        </section>

        <section className="category-content">
          <h2>Recently Posted</h2>
          <section className="event-container">
            {recentEventsData &&
              recentEventsData.map((d) => (
                <div className="event-card" key={d.id}>
                  <img className="event-image" src={event_image} alt="Event" />
                  <div className="event-overlay">
                    <div className="event-details">
                      <button
                        onClick={() => navigateToEvent(d.id)}
                        className="event-text"
                      >
                        {d.title}
                      </button>
                    </div>
                    <button
                      onClick={() => navigateToReview(d.id)}
                      className="review-btn"
                    >
                      Leave Review
                    </button>
                  </div>
                </div>
              ))}
          </section>
        </section>

        <section className="category-content">
          <h2>All Events</h2>
          <section className="event-container">
            {allEventsData &&
              allEventsData.map((d) => (
                <div className="event-card" key={d.id}>
                  <img className="event-image" src={event_image} alt="Event" />
                  <div className="event-overlay">
                    <div className="event-details">
                      <button
                        onClick={() => navigateToEvent(d.id)}
                        className="event-text"
                      >
                        {d.title}
                      </button>
                    </div>
                    <button
                      onClick={() => navigateToReview(d.id)}
                      className="review-btn"
                    >
                      Leave Review
                    </button>
                  </div>
                </div>
              ))}
          </section>
        </section>

        <section className="category-content">
          <h2>Past Events</h2>
          <section className="event-container">
            {pastEventsData &&
              pastEventsData.map((d) => (
                <div className="event-card" key={d.id}>
                  <img className="event-image" src={event_image} alt="Event" />
                  <div className="event-overlay">
                    <div className="event-details">
                      <button
                        onClick={() => navigateToEvent(d.id)}
                        className="event-text"
                      >
                        {d.title}
                      </button>
                    </div>
                    <button
                      onClick={() => navigateToReview(d.id)}
                      className="review-btn"
                    >
                      Leave Review
                    </button>
                  </div>
                </div>
              ))}
          </section>
        </section>
      </div>
    </div>
  );
}

export default DashboardPage;
