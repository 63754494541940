import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "./UserContext";
import "./SignupPage.css";
import axios from "axios";

function SignupPage() {
  const { setUser } = useUser();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [invalidSignup, setInvalidSignup] = useState(false);

  const handleSignup = async () => {
    const userData = {
      username: username,
      password: password,
    };
    console.log(userData);

    if (userData.username === "" || userData.password === "") {
      setInvalidSignup(true);
      return;
    }

    try {
      const signup = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/signup`, userData
      );
      console.log(signup.data);
      
      if (!signup.data.message) {
        setUser({ 
            id: signup.data.id,
            username: signup.data.username
          });
        localStorage.setItem("id", signup.data.id);
        localStorage.setItem("username", signup.data.username);
        navigate("/dashboard");
      } else {
        setInvalidSignup(true);
        console.log("Invalid signup");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSwitchToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="signup-container">
      <div className="signup-box">
        <h1>Sign Up</h1>
        <input
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input-field"
        />
        <br></br>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="input-field"
        />
        <br></br>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input-field"
        />
        <br></br>
        <button onClick={handleSignup} className="button">
          Sign Up
        </button>
        <br></br>
        <button onClick={handleSwitchToLogin} className="switch-to-login">
          Already have an account? Login
        </button>
        {invalidSignup === true ? (
          <div>
            <br></br>
            <button className="invalid-signup">Invalid signup.</button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SignupPage;
