import { useState, useEffect } from 'react';
import './AddEventPage.css';
import Sidebar from './Sidebar'; // Import the Sidebar component
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function AddEventPage() {
  const navigate = useNavigate();

  const [eventData, setEventData] = useState({
    title: '',
    location: '',
    date: '',
    end_date: '',
    category: 'Other',
    description: '',
    participant_limit: '',
    rsvp_required: false
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/events/add/event/${localStorage.getItem("id")}`, eventData
      );
      //const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/events/add/event/${hardcodedUserId}`, eventData);
      console.log(response.data);
      alert('Event added successfully!');
    } catch (error) {
      console.error('Error adding event:', error);
      alert('Failed to add event.');
    }
    navigate("/dashboard");
  };

  useEffect(() => {
    if (!localStorage.getItem("id")) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="add-event-container"> {/* Change the className to a container that will hold both sidebar and the form */}
     <Sidebar /> {/* This adds the Sidebar to the page */}
    <div className="add-event-page">
      <h1>Create New Event</h1>
      <form onSubmit={handleSubmit} className="add-event-form">
        <input type="text" name="title" value={eventData.title} onChange={handleInputChange} placeholder="Event Title" required />
        <input type="text" name="location" value={eventData.location} onChange={handleInputChange} placeholder="Event Location" required />
        <input type="datetime-local" name="date" value={eventData.date} onChange={handleInputChange} required />
        <input type="datetime-local" name="end_date" value={eventData.end_date} onChange={handleInputChange} required />
        <select name="category" value={eventData.category} onChange={handleInputChange} required>
          <option value="Academic">Academic</option>
          <option value="Career">Career</option>
          <option value="Sports">Sports</option>
          <option value="Entertainment">Entertainment</option>
          <option value="Other">Other</option>
        </select>
        <textarea name="description" value={eventData.description} onChange={handleInputChange} placeholder="Event Description"></textarea>
        <input type="number" name="participant_limit" value={eventData.participant_limit} onChange={handleInputChange} placeholder="Participant Limit" />
        <label>
          RSVP Required:
          <input type="checkbox" name="rsvp_required" checked={eventData.rsvp_required} onChange={e => setEventData(prevState => ({ ...prevState, rsvp_required: e.target.checked }))} />
        </label>
        <button type="submit">Add Event</button>
      </form>
    </div>
    </div>
  );
}

export default AddEventPage;