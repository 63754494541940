import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import "./ReviewPage.css";

// starRating component
const StarRating = ({ rating, onRating, hoverRating, onHoverRating }) => {
  return (
    <div className="star-rating">
      {[...Array(5)].map((_, index) => {
        const ratingValue = index + 1;
        return (
          <button
            type="button"
            key={ratingValue}
            className={ratingValue <= (hoverRating || rating) ? "on" : "off"}
            onClick={() => onRating(ratingValue)}
            onMouseOver={() => onHoverRating(ratingValue)}
            onMouseOut={() => onHoverRating(0)}
          >
            <span className="star">&#9733;</span>
          </button>
        );
      })}
    </div>
  );
};

function ReviewPage() {
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0); // State to keep track of hover

  const [reviewText, setReviewText] = useState("");

  const [eventData, setEventData] = useState([]);
  const [ratedBefore, setRatedBefore] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();

  function get_date(datetime) {
    var date = new Date(datetime);
    var full_date =
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + date.getDate()).slice(-2) +
      "/" +
      date.getFullYear();
    return full_date;
  }

  function get_time(datetime) {
    var date = new Date(datetime);
    var time =
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2);
    return time;
  }

  const handleBackToDash = () => {
    navigate("/dashboard");
  };

  // Updated handleSubmitReview function accordingly
  const handleSubmitReview = async () => {
      const reviewData = {
        author: localStorage.getItem("id"),
        rating: rating,
        comment: reviewText
      };
      console.log(reviewData)

      try {
        const rating = axios.post(
          `${process.env.REACT_APP_BASE_URL}/ratings/add/${state.id}`, reviewData
        );
        console.log(rating.message);

      } catch (error) {
        console.log(error);
      }

      alert(`You submitted a review with a rating of ${rating} stars! Your review: "${reviewText}"`);
      navigate("/dashboard");
  };

  const handleCantSubmitReview = async () => {
      alert(`You have already reviewed this event!`);
  };

  useEffect(() => {
    const getEventData = async () => {
      try {
        const event = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/events/get/${state.id}`
        );
        console.log(event.data)
        setEventData(event.data);
      } catch (error) {
        console.log(error);
      }
    }

    const getRatingData = async () => {
      try {
        const reviews = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/profile/getUserReviews?user_id=${localStorage.getItem("id")}`
        );
        console.log(reviews.data);
        
        if (reviews.data.length > 0) {
          for (var i=0;i < reviews.data.length;i++) {
            if (reviews.data[i][2] === state.id && reviews.data[i][1] === localStorage.getItem("id")) {
              setRatedBefore(true);
              break
            }
          }
        }

      } catch (error) {
        console.log(error);
        setRatedBefore(true);
      }
    }

    getEventData();
    getRatingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="rp-page-container">
      <Sidebar />
      <div className="rp-review-container">
        <button className="back-button" onClick={handleBackToDash}>
          Back to Dashboard
        </button>
        <h2>Leave a Review</h2>
        <div className="rp-event-details">
          {eventData &&
            eventData.map((d) => (
              <div key={d.event_id}>
                <h3>{d.title}</h3>
                <p>Hosted by: {d.host}</p>
                <p>
                  Date: {get_date(d.date)} to {get_date(d.end_date)}
                </p>
                <p>
                  Time: {get_time(d.date)} to {get_time(d.end_date)}
                </p>
              </div>
            ))}
        </div>
        <div className="rp-rating-section rp-input-group">
          <label>Rating</label>
          <StarRating
            rating={rating}
            onRating={setRating}
            hoverRating={hoverRating}
            onHoverRating={setHoverRating}
          />
        </div>
        <div className="rp-input-group">
          <label>Review</label>
          <textarea
            rows="16"
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
            placeholder="Write your review here..."
            className="rp-textarea"
          />
          {ratedBefore === false ? 
            (
              <div>
                <button onClick={handleSubmitReview} className="rp-submit-review-btn">
                  Submit Review
                </button>
              </div>
            ) : (
              <div>
                <button onClick={handleCantSubmitReview} className="rp-not-submit-review-btn">
                  Submit Review
                </button>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default ReviewPage;
