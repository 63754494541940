import React from "react";
import logo from "./images/logo4.png";
import "./Sidebar.css";
import { useNavigate } from "react-router-dom";

function Sidebar() {
  const navigate = useNavigate();

  function navigateToHome() {
    navigate("/Dashboard");
  }

  function navigateToUserStats() {
    navigate("/userstats");
  }

  function navigateToMyEvents() {
    navigate(`/profile/${localStorage.getItem("id")}/events`);
  }
  
  function navigateToPostEvent() {
    navigate("/addevent");
  }

  return (
    <div className="sidebar">
      <img
        src={logo}
        alt="Fly High Logo"
        className="logo-img"
        onClick={navigateToHome}
      />
      <div className="logo">Welcome to Fly High{localStorage.getItem("username") ? ( <span>, {localStorage.getItem("username")}</span> ) : ( null )}</div>
      <br></br>
      <button onClick={navigateToMyEvents} className="btn">
        My Events
      </button>
      <button onClick={navigateToUserStats} className="btn">
        User Stats
      </button>
      <button onClick={navigateToPostEvent} className="btn">Post Event</button>
      <button
        className="btn"
        onClick={() => {
          localStorage.removeItem("username");
          localStorage.removeItem("id");
          navigate("/login");
        }}
      >
        Logout
      </button>
    </div>
  );
}

export default Sidebar;
