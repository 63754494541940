// EventsPage.js
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import "./EventsPage.css";
import Sidebar from "./Sidebar";

import event_image from "./images/eventpageplaceholder-image.jpg";

const fullStar = '★';
const emptyStar = '☆';

const StarRating = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 >= 0.5 ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStar;

  return (
    <div className="event-star-rating">
      {Array(fullStars).fill(fullStar).map((s, i) => <span key={i}>{s}</span>)}
      {halfStar ? <span>{fullStar}</span> : null}
      {Array(emptyStars).fill(emptyStar).map((s, i) => <span key={i}>{s}</span>)}
    </div>
  );
};

const EventsPage = () => {
  const [eventData, setEventData] = useState([]);
  const [ratingData, setRatingData] = useState([]);
  const [avgRatingData, setAvgRatingData] = useState([]);
  const [numRSVP, setNumRSVP] = useState(0);
  const { state } = useLocation();
  
  const handleRSVP = async (eventId) => {
    if (!localStorage.getItem("id")) {
      alert("User is not logged in.");
      return;
    }

    const userData = {
      user_id: localStorage.getItem("id")
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/events/add/rsvp/${eventId}`,
        userData
      );
      console.log(response.data);
      alert("RSVP successful!");
      // Optionally, update the numRSVP state to reflect the new count
      setNumRSVP((prevCount) => prevCount + 1);
    } catch (error) {
      console.error("Error RSVPing:", error);
      alert("You've already RSVP'd.");
    }
  };
  
  function get_date(datetime) {
    var date = new Date(datetime);
    var full_date = ("0" + (date.getMonth() + 1)).slice(-2) + '/' + ("0" + date.getDate()).slice(-2) + '/' + date.getFullYear();
    return full_date;
  }

  function get_time(datetime) {
    var date = new Date(datetime);
    var time = ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2);
    return time;
  }

  const navigate = useNavigate();
  const handleBackToDash = () => {
    navigate("/dashboard");
  }

  useEffect(() => {
    const getEventData = async () => {
      try {
        const event = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/events/get/${state.id}`
        );
        console.log(event.data)
        setEventData(event.data);
      } catch (error) {
        console.log(error);
      }
    }
    
    const getRatingData = async () => {
      try {
        const rating = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/ratings/get/${state.id}`
        );
        console.log(rating.data)
        setRatingData(rating.data);
      } catch (error) {
        console.log(error);
      }
    }
    
    const getAvgRatingData = async () => {
      try {
        const avg = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/ratings/get/avg/${state.id}`
        );
        console.log(avg.data)
        setAvgRatingData(avg.data);
      } catch (error) {
        console.log(error);
      }
    }
    
    const getNumRSVP = async () => {
      try {
        const rsvp = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/events/get/rsvp/${state.id}`
        );
        console.log(rsvp.data)
        if (rsvp.data.message) {
          setNumRSVP(0);
          return
        } else {
          setNumRSVP(rsvp.data.length);
        }
      } catch (error) {
        console.log(error);
      }
    }
    
    getEventData();
    getRatingData();
    getAvgRatingData();
    getNumRSVP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="events-page">
      <Sidebar />
      <div className="event-content">
        <button className="back-button" onClick={handleBackToDash}>
            Back to Dashboard
        </button>
        {eventData && eventData.map((d) => (
          <div key={d.event_id}>
            <div className="event-header">
              <span><strong>Host:</strong> {d.host}</span>
              <h1>Title: {d.title}</h1>
              <span><strong>Max Number of Attendees:</strong> {d.participant_limit}</span><br></br>
              <span><strong>Date:</strong> {get_date(d.date)} to {get_date(d.end_date)}</span><br></br>
              <span><strong>Time:</strong> {get_time(d.date)} to {get_time(d.end_date)}</span>
            </div>
            <img src={event_image} alt="PLACEHOLDER" className="placeholder-photo" />
            <div className="event-info">
              <div>
                <span className="avg-rating"><strong>Avg rating:</strong> 
                <StarRating rating={parseFloat(avgRatingData[0]?.avg || 0).toFixed(2)} />
                </span>

                <span className="people-going"><strong>Number of people going:</strong> {numRSVP}</span>
              </div>
                <button
                  className="rsvp-button"
                  onClick={() => handleRSVP(d.event_id)}
                >
                  RSVP
                </button>
            </div>
            <div className="event-description">
              <p><strong>Event Description:</strong> {d.description}</p>
              <p><strong>Category:</strong> {d.category}</p>
              <p><strong>Reviews:</strong></p>
              {ratingData && ratingData.map(data => (
                <div key={data.id} className="event-page-box-review">
                  <p className="author-name">{data.author_name}</p>
                  <div className="comment-rating">
                    <StarRating rating={data.rating} />
                    <p className="comment">{data.comment}</p>
                  </div>
                  <p className="post-date">Posted on: {data.date}</p>
                </div>
              ))}
            </div>
          </div>
          ))}
      </div>
    </div>
  );
};

export default EventsPage;
