import React, { useEffect, useState } from "react";
import axios from "axios";
import "./UserStatsPage.css";
import Sidebar from "./Sidebar";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { useNavigate } from 'react-router-dom';

function UserStatsPage() {
  const [userMetrics, setUserMetrics] = useState({
    total_events_created: 0,
    total_rsvps_received: 0,
    average_event_rating: 0,
    upcoming_events_count: 0,
  });
  
  const navigate = useNavigate();

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Growth of RSVPs per Hosted Event", // If you want to add a title to your chart
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Your Sequential Events Hosted", // The title for the X axis
        },
        grid: {
          display: false, // This will hide the X-axis grid lines
        },
      },
      y: {
        title: {
          display: true,
          text: "RSVP Count", // The title for the Y axis
        },
        grid: {
          display: false, // This will hide the X-axis grid lines
        },
        beginAtZero: true, // This ensures the scale starts at zero
        ticks: {
          // This ensures only whole numbers are used in the scale
          precision: 0, // Forcing zero decimal places
          stepSize: 1, // Setting the step size to 1 will enforce whole numbers
        },
      },
    },
  };

  const [eventGrowthData, setEventGrowthData] = useState({
    labels: [],
    datasets: [
      {
        label: "RSVP Count",
        data: [],
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    if (!localStorage.getItem("id")) {
      navigate("/login");
    }

    // Call to fetch user metrics
    axios
      .get(
        process.env.REACT_APP_BASE_URL +
          "/userstats/get/user_metrics/" +
          localStorage.getItem("id")
      )
      .then((response) => {
        console.log("User metrics: ", response.data);
        setUserMetrics(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user metrics: ", error);
      });

    // New API call for event growth data
    axios
      .get(
        process.env.REACT_APP_BASE_URL +
          "/userstats/get/user_growth/" +
          localStorage.getItem("id")
      )
      .then((response) => {
        const growthData = response.data;
        const labels = growthData.map((data) => `Event ${data.event_order}`);
        const rsvpCounts = growthData.map((data) => data.rsvp_count);

        setEventGrowthData({
          labels: labels,
          datasets: [
            {
              label: "RSVP Count",
              data: rsvpCounts,
              backgroundColor: "rgba(54, 162, 235, 0.2)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
            },
          ],
        });
      })
      .catch((error) => {
        console.error("Error fetching event growth data: ", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const MetricCard = ({ title, value }) => (
    <div className="metric-card">
      <h2>{title}</h2>
      <p>{value}</p>
    </div>
  );

  return (
    <div className="page-container">
      <Sidebar />
      <div className="content-container">
        <div className="metrics-container">
          <MetricCard
            title="Total Events Created"
            value={userMetrics.total_events_created}
          />
          <MetricCard
            title="Total RSVPs Received on all Events"
            value={userMetrics.total_rsvps_received}
          />
          <MetricCard
            title="Average Event Rating"
            value={userMetrics.average_event_rating}
          />
          <MetricCard
            title="Upcoming Events Planning to Attend"
            value={userMetrics.upcoming_events_count}
          />
        </div>
        <div className="chart-container">
          <Bar data={eventGrowthData} options={options} />
        </div>
      </div>
    </div>
  );
}

export default UserStatsPage;
